import React, { useState } from "react"
import { PaystackButton } from 'react-paystack';
import axios from "axios";
import swal from "sweetalert";


function PaymentSys({ UserData, submitForm }) {
    const ContactIvyarc = "info@ivyarc.com"
    const [signUpSuccess, SetSignUpSuccess] = useState(false)
    const config = {
        reference: (new Date()).getTime(),
        email: UserData.email,
        amount: UserData.amount,
        publicKey: UserData.publicKey,
    };

    // you can call this function anything
    const handlePaystackSuccessAction = (reference) => {
        UserData.transactionId = reference.reference
        const Data = UserData;
        axios.post('https://apihackathon.ivyarc.com/v1/users/sign-up', Data)
            .then(res => {
                SetSignUpSuccess(true)
                swal({
                    title: "Registered!",
                    text: "Registeration successful check your mail for instructions",
                    icon: 'success',
                    button: true
                })
            })
            .catch(err => {
                SetSignUpSuccess("error")
                swal({
                    text: `Please email us at: ${ContactIvyarc}`,
                    icon: 'error',
                    title: "Registration failed!",
                })
            }
            )


        // Implementation for whatever you want to do with reference and after success call.
        // submitForm()
        console.log("reference", reference);
    };

    // you can call this function anything
    const handlePaystackCloseAction = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        console.log('closed')
    }

    const componentProps = {
        ...config,
        text: signUpSuccess === true ? `Record saved😎` : (signUpSuccess === "error") ? "Error Data!" : `${UserData.text}`,
        onSuccess: (reference) => handlePaystackSuccessAction(reference),
        onClose: handlePaystackCloseAction,
    };
    return (
        <>
            <PaystackButton {...componentProps} className="but1" />
        </>
    )
}

export default PaymentSys;