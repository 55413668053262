import React from 'react'
import DeskIMG from "../../images/hack1.jpg"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

function Content() {



  return (
    <div className="mainContainer" >

      <div className="mainImg-container">
        <img className="mainImg" src={DeskIMG} alt="content" />
      </div>
      <div>
        <div className="mainContainer-content-theme">Theme: COVID-19 No Limitation to Communication</div>
        <div className="mainContainer-content-Organizer">Organized by <a href="https://ivyarc.com">IVYARC</a></div><br />
        <p className="write">
          IVYARC will help solve the global talent crisis by empowering young ambitious Africans through skills transfer, and exposure to modern Information Technology (IT) practices and ways of working. <br />
          To get started, register for the event between the 11th May - 7th June, 2021. Afterwards, make a pull request from the Git repository link that will be sent to your registered email; and begin integrating the required features into the cloned application.
          <br />Contributors are to submit their solution between 7th June - 4th July, 2021 by sending the Git Repo link to their solution to hackathon@ivyarc.com. Top 10 solutions will be selected and the winners will be announced on the 14th of July 2021. 
          <button className="read" data-toggle="modal" data-target="#myModal1" > Read More</button></p>
        {/* <div class="modal-dialog modal-dialog-centred"> */}
        <button className="btn btn-outline-light btn-lg" data-toggle="modal" data-target="#exampleModalCenter" >Register</button>
      </div>

    </div>
    //  </div>
  )
}

export default Content
