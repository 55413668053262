import React, { useState } from 'react'
import axios from "axios"
import PaymentSys from '../PaymentSystem/Payment'

function FormModal() {
    const publicKey = "pk_live_4d62a0435d42ca2f21db6963985e858ab7facebd"
    const amount = 100000;

    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        linkedIn: "",
        github: "",
        acceptTerms: false,
        transactionId: "hiidididiidid55678",
    })

    const { email, firstName, lastName, phone, linkedIn, github, transactionId, acceptTerms } = values

    const handleChange = (type) => (e) => {
        setValues({ ...values, [type]: e.target.value })
    }
    const UserData = {
        email,
        amount,
        firstName,
        lastName,
        phone,
        linkedIn,
        github,
        transactionId,
        publicKey,
        acceptTerms,
        text: "Pay Now"
    }

    function submitForm(e) {
        e.preventDefault();

        const Data = UserData;
        axios.post('https://apihackathon.ivyarc.com/v1/users/sign-up', Data)
            .then(res => console.log('DATA SUBMITTED', res))
            .catch(err => console.log('DATA ERROR', err))
    }


    return (
        <div className="modal fade" id="exampleModalCenter" tableindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" >

            <div className="modal-dialog modal-xl" role="document" >
                <div className="modal-content" style={{ margin: "10% auto" }}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalCenterTitle">Hackathon Form</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <form>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label for="firstName">First Name</label>
                                        <input type="firstName" onChange={handleChange("firstName")} className="form-control" value={firstName} placeholder="Enter First Name" required></input>
                                    </div>
                                    <div className="form-group">
                                        <label for="lastName">Last Name</label>
                                        <input type="lastName" onChange={handleChange("lastName")} className="form-control" value={lastName} placeholder="Enter Last Name" required></input>
                                    </div>
                                    <div className="form-group">
                                        <label for="exampleInputEmail1">Email Address</label>
                                        <input type="email" onChange={handleChange("email")} className="form-control" value={email} aria-describedby="emailHelp" placeholder="Enter email" required></input>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label for="phone">Phone Number</label>
                                        <input type="phone" onChange={handleChange("phone")} className="form-control" value={phone} placeholder="Enter Phone Number" required></input>
                                    </div>
                                    <div className="form-group">
                                        <label for="linkedin">LinkedIn</label>
                                        <input type="linkedin" onChange={handleChange("linkedIn")} className="form-control" value={linkedIn} placeholder="Enter your LinkedIn Account" required></input>
                                    </div>
                                    <div className="form-group">
                                        <label for="gitHub">Git Hub</label>
                                        <input type="gitHub" onChange={handleChange("github")} className="form-control" value={github} placeholder="Enter your GitHub Account" required></input>
                                    </div>
                                </div>
                            </div>
                            <div className="checkBox-tick"><input type="checkbox" onChange={handleChange("acceptTerms")} value={true} name="vehicle3" required /> <span className="TnC">By checking this box, you agree to the <span data-toggle="modal" data-target="#TYandD" className="Checkbox-form-CT">terms and conditions</span> for the IVYARC Hackathon</span></div>
                            <div className="form-group">
                                <label for="amount" style={{ textAlign: "center" }}>Amount</label>
                                <input type="text" className="form-control" style={{ textAlign: "center" }} value="₦1000" placeholder="₦1000" readOnly required></input>
                            </div>

                        </form>
                        <div className="modal-footer">
                            <div>
                                {acceptTerms === false ?
                                    <div type="button" className="but2" ><div>Pay Now</div></div> :
                                    <PaymentSys type="submit" UserData={UserData} submitForm={submitForm} />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal" id="TYandD" >
                <div className="modal-dialog modal-xl" role="document" style={{ marginTop: "30px" }}>

                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title modal-TndD-Title">ivyarc hackathon terms and conditions</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body modal-content">
                            <div className="TnD-Header">Acceptance</div>
                            By submitting your registration for the IVYARC Hackathon you agree to accept and abide by the following IVYARC Hackathon Terms and Conditions.
                            <div className="TnD-Header">IVYARC Hackathon & Eligibility</div>
                            <ul>1.	IVYARC Hackathon is hosted online and organized by IVYARC from 5th July 2021, until 14th July 2021.</ul>
                            <ul>2.	IVYARC Hackathon is open to everyone who is at least 18 years of age and holds a minimum of HND/Bachelor Degree or any other relevant qualification.</ul>
                            <ul>                            3.	Participation in the IVYARC Hackathon cost a sum of One Thousand Naira (N1000) only. It is open to One Thousand Five Hundred participants only.</ul>
                            <div className="TnD-Header">Registration & Selection of Participants</div>
                            <ul>1.	All applicants must register as an individual before 8th June 2021. Registration is only possible through the online registration form.</ul>
                            <ul>2.	 Each applicant must enter the required information on the registration form. No late submissions will be accepted and any incomplete, false, erroneous, fraudulent, forged, tampered forms will not be considered.</ul>
                            <ul>3.	All registered participants will be informed by formal acceptance notice via email.</ul>

                            <div className="TnD-Header">Competition/ Judging</div>
                            <ul>1.	Each participant will submit only one solution for competition.</ul>
                            <ul> 2.	Contributors/Participants are to submit their solution between 7th June - 4th July, 2021 by sending the Git Repo link to their solution to hackathon@ivyarc.com.</ul>
                            <ul>  3. 	Submitted solutions will then be assessed by a panel of judges consisting of top-tier Software Engineers from 5th July – 9th July 2021.</ul>
                            <ul> 4.	The top 10 solutions will be selected and the three (3) winners will be announced on the 14th of July 2021.</ul>
                            <div className="TnD-Header">Ideas & Intellectual Property</div>
                            <ul>1.	All rights concerning the entries shall remain with the participant(s). Participants agree to share their ideas with all others at the IVYARC Hackathon. IVYARC does not accept any responsibility to protect participant’s ideas, works and results disclosed or generated during the IVYARC Hackathon.</ul>
                            <ul>  2.	Participant acknowledges and agrees that IVYARC or other participants or third parties may have developed or commissioned ideas, works or results which are similar to the entry of participants  or may develop something similar in the future. Each participant waives any claims that participant may have against IVYARC resulting from any similarities to the entry of participant.</ul>
                            <ul>3.	All data sets, hardware and other equipment that may be made available by IVYARC to participants during or in connection with the IVYARC Hackathon shall remain the sole and exclusive property of IVYARC.</ul>

                            <div className="TnD-Header">Instructions & Behavior</div>
                            <ul>1.	Participants acknowledge the domestic authority of IVYARC and will comply with instructions given by IVYARC, its legal representatives or officials.</ul>
                            <ul> 2.	A participant or team may be disqualified for any committed unlawful act. Reasons for disqualification may include, but are not limited to, fraud, any other form of dishonesty, misappropriation or infringement of the intellectual property of others, misuse of IVYARC intellectual property, and/or any failure to comply with these IVYARC Hackathon Terms and Conditions.</ul>


                            <div className="TnD-Header">Liability</div>
                            IVYARC shall not be liable for any damages or claims that might arise or occur during or in connection with the IVYARC Hackathon, except in cases of intent or gross negligence on the part of IVYARC. Participants shall indemnify and hold IVYARC harmless from and against any claims of third parties arising from any violation of any third party right or any other unlawful act committed during the IVYARC Hackathon or from any breach of these IVYARC Hackathon Terms and Conditions.
                            <div className="TnD-Header">Data Privacy</div>
                            <ul>   1.	The personal data of the applicants/ participants will be used in accordance with the Nigerian Data Protection and Privacy law (1999). By registering, the applicant agrees that his/her personal data may be stored and used by IVYARC within the course and for the purpose of organization and carrying out the IVYARC Hackathon. This consent can be revoked at any time whereupon the registration in the IVYARC Hackathon will be automatically cancelled then. Stored personal data will be deleted upon revocation or at the latest after the end of the IVYARC Hackathon. No personal data will be given to third parties without the express permission of the applicant.</ul>
                            <ul>   2.	Participant agrees that any pictures being taken within the course of the Hackathon may be used for future promotional, marketing, publicity and recruitment purposes without claim to remuneration.</ul>


                            <div className="TnD-Header">Prizes</div>
                            <ul>1st Prize: N150,000.00 with AWS Sponsored course</ul>
                            <ul>2nd Prize: AWS sponsored course</ul>
                            <ul>3rd Prize: AWS Sponsored course</ul>

                            *Prizes may be replaced by equivalent items, such change to be announced a.s.a.p. if any.
                            The winners will be announced on 14th July 2021.
                            Each winner will be solely responsible for complying with any and all applicable laws, rules, and regulations relating to the prize. IVYARC does not assume and hereby disclaims any liability in this respect.

                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormModal
