import React from 'react'

function ReadMoreModal() {
    return (
        <div className="modal" id="myModal1" >

            <div className="modal-dialog modal-xl" role="document" style={{ marginTop: "30px" }}>

                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Read More</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>Our 2021 Hackathon is open to Software Engineers that have a strong drive towards creating a brighter future for Africa and the World at large. The event is aimed at the integrating new features to a cloned Video Conferencing
                        software that will greatly improve the daily operations of businesses during this COVID-19 restricted times.<br /><br />
                        The winner of the Hackathon will be awarded with a sum of 150,000 Naira and access to a fully sponsored Amazon Web Service (AWS) Training Program.<br /><br />
                        IVYARC is organising a Hackathon that is geared at solving the issues that popular video calling software, such as Skype, Zoom, and Google Meet, encounter daily.
                        The event aims at solving these issues by making use of the budding yet potential-filled software developers that Nigeria has to offer.<br /><br />

                        The task is to integrate the following features into the cloned video-conferencing application.<br /><br />
                            <ol className="list">
                                <li>Must offer Two-Way Authentication security to ensure that all information shared in video calls cannot be intercepted by external bodies.</li>
                                <li>Must offer efficient Internet Data Consumption rates that will on-market video conferencing apps away.</li>
                                <li>Must offer efficient Business Portability by being able to be used on both public Internet connections and on businesses' private Virtual Private Networks (VPN).</li>
                                <li>Must have much better Internet Connectivity/Stability (reduced meeting participant disconnections and much better audio and video quality) than what current video conferencing offer.</li>
                                <li>Having Video Replay and Replay Speed Manipulation (Pause, Fast-forward, and Rewind) features that will enable meeting participants to easily revisit older sections of the meeting that they want to review or view parts of the meeting that they have been absent for.</li>
                                <li>Must offer Speech-to-Text functionality to enable the creation of real-time captions (subtitles) of what the meeting participants are saying.</li>
                                <li>Must implement Real-time Transcription functionality that will have a log of what the meeting participants have discussed (see criteria 6), as well as identifiers for who-said-what during the meeting.</li>
                                <li>Must offer a dedicated and categorised Shared Content section that will keep a list of all content that has been shared throughout the meeting. The categories are up to your discretion, but some good examples are Documents, Audio, and Links categories.</li>
                                <li>Must be deployable on both Web and Mobile platforms. The preferred mobile platforms are Android and iOS but be free to surprise us. 🖒</li>

                            </ol>
                          Yes, quite a list of criteria to meet, but worry not as we have given you a good start and have a readily cloneable mock-up application that you can access from a GitHub repository that will be provided.<br />
                          So, no need to worry, leap of fate and get coding. ;)<br /><br />

                          We are expecting great things from you. 🖒<br /><br />


                          Registration and Winnings<br /><br />
                          If you are interested in participating in this Hackathon, please register with the button below.<br /><br />

                          The top-three entries will be awarded with a fully-sponsored AWS Training program, with the 1st place winner also getting an additional 150,000 Naira.<br /><br />

                          So, get started today: Register and start Coding the Future.<br /><br />
                        </p>
                    </div>
                    <div className="modal-footer">
                        Good Luck!! 🖒🖒
                      </div>
                </div>
            </div>
        </div>

    )
}

export default ReadMoreModal
